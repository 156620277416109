import state from './moduleMenuState.js'
import mutations from './moduleMenuMutations.js'
import actions from './moduleMenuActions.js'
import getters from './moduleMenuGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
