export default {
  ADD_ITEM(state, item) {
    state.menus.unshift(item)
  },
  SET_MENUS(state, menus) {
    state.menus = menus
  },
  UPDATE_ITEM(state, menu) {
    const menuIndex = state.menus.findIndex((p) => p.id === menu.id)
    Object.assign(state.menus[menuIndex], menu)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.menus.findIndex((p) => p.id === itemId)
    state.menus.splice(ItemIndex, 1)
  },
  CHANGE_STATUS_ITEM(state, menu) {
    const menuIndex = state.menus.findIndex((p) => p.id === menu.id)
    Object.assign(state.menus[menuIndex], menu)
  }
}
