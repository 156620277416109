import firebase from 'firebase/app'
import 'firebase/firestore';

export default {
  addItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('tipos-cardapio')
        .doc(item.id.toString())
        .set({
          nome: item.nome,
          fundo: item.fundo,
          fundo_imagem: item.fundo_imagem,
          ordenar: item.ordenar,
          ativo: item.ativo,
          removido: item.removido
        })
        .then((response) => {
          commit('ADD_ITEM', item)
          // commit('ADD_ITEM', Object.assign(item, {
          //   id: item.id
          // }))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMenuItems({
    commit,
    rootGetters
  }) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('tipos-cardapio')
        .orderBy('ordenar')
        .where('removido', '==', false)
        .get()
        .then((snapshot) => {
          const menus = []
          snapshot.forEach(doc => {
            menus.push({
              id: doc.id,
              nome: doc.data().nome,
              fundo: doc.data().fundo,
              fundo_imagem: doc.data().fundo_imagem,
              ordenar: doc.data().ordenar,
              ativo: doc.data().ativo
            });
          });
          commit('SET_MENUS', menus)
          resolve(snapshot)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('tipos-cardapio')
        .doc(item.id.toString())
        .update({
          nome: item.nome,
          ativo: item.ativo,
          fundo_imagem: item.fundo_imagem
          //ordenar: item.ordenar
        })
        .then((response) => {
          commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({
    commit,
    rootGetters
  }, itemId) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('tipos-cardapio')
        .doc(itemId)
        .update({
          removido: true
        })
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeStatusItem({
    commit,
    rootGetters
  }, item) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('estabelecimentos')
        .doc(rootGetters.establishmentId)
        .collection('tipos-cardapio')
        .doc(item.id.toString())
        .update({
          ativo: item.ativo
        })
        .then((response) => {
          commit('CHANGE_STATUS_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
